import React, { PropsWithChildren } from 'react';
import Spin, { SpinSize, SpinProps } from 'antd/lib/spin';

import * as S from './styles';

type Props = PropsWithChildren<{
  size?: SpinSize;
  backgroundColor?: Colors;
}> &
  SpinProps;

const Loading = ({ children, size = 'large', backgroundColor = 'orange', ...rest }: Props) => {
  // TODO: types
  const SpinnerComponent = (children ? Spin : S.Spinner) as any;

  return (
    <S.Wrap $loadingColor={backgroundColor} className="loading" data-testid="loading">
      <SpinnerComponent size={size} {...rest}>
        {children}
      </SpinnerComponent>
    </S.Wrap>
  );
};

export default Loading;
