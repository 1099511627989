import { DocumentNode, TypedDocumentNode, useApolloClient } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';

type QueryTuple<TResult = any, TVariables = any> = [
  query: DocumentNode | TypedDocumentNode<TResult, TVariables>,
  variables: TVariables
];

type VoidFn = () => void;
type CallbackTransformer<TResult = any, TTransformed = any> = (result: TResult) => TTransformed;

const useApolloCacheWatcher = (queries: QueryTuple[], transformCallback: CallbackTransformer) => {
  const client = useApolloClient();
  const callbackRef = useRef(transformCallback);
  const [, triggerUpdate] = useState<number>(0);
  const dataRef = useRef(new Map());

  // Keep the callback reference up to date without re-registering effects
  useEffect(() => {
    callbackRef.current = transformCallback;
  }, [transformCallback]);

  useEffect(() => {
    const watchers: VoidFn[] = [];

    queries.forEach(([query, variables]) =>
      watchers.push(
        client.cache.watch({
          query,
          variables,
          optimistic: false,
          callback({ complete, result }) {
            if (!complete || !result) return;

            callbackRef.current(result);

            // Trigger re-render by updating a counter
            triggerUpdate((c) => c++);
            dataRef.current.set(query, callbackRef.current(result));
          },
        })
      )
    );

    return () => {
      return watchers.forEach((unsubscriber) => unsubscriber());
    };
  }, [client, queries]);

  return [...dataRef.current.values()];
};

export default useApolloCacheWatcher;
